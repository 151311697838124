import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppConfig } from '../../AppConfig';
import { OauthService } from '../../core/services/oauth.service';
import { getQueryParams } from '../../utils';
import { APP_CONFIG } from '../injectionTokens';
import { NavigationService } from '../services/NavigationService';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private oauthService: OauthService,
        private navigationService: NavigationService,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
    ) {}

    canActivate(): boolean {
        const currentUrl = this.navigationService.getUrl();
        const { return_to: returnTo, jwt } = getQueryParams(currentUrl);

        if (!this.oauthService.isAuthenticated() && !returnTo && !jwt) {
            this.goToSsoLoginPage(currentUrl);
            return false;
        }

        if (returnTo) {
            const redirectionUrl = this.decodeReturnUrl(returnTo);
            if (!this.urlsHaveSameDomain(redirectionUrl, currentUrl)) {
                this.switchToRedirectionUrlDomain(redirectionUrl, currentUrl);
                return false;
            }
        }

        if (jwt) {
            const redirectionUrl = this.decodeReturnUrl(returnTo);
            this.oauthService
                .authenticate(jwt)
                .pipe(tap(() => this.navigationService.gotoUrl(redirectionUrl)))
                .subscribe();
            return false;
        }

        return true;
    }

    private goToSsoLoginPage(currentUrl: string) {
        this.navigationService.gotoUrl(`${this.appConfig.sso.servegoServiceUrl}?return_to=${btoa(currentUrl)}`);
    }

    private switchToRedirectionUrlDomain(redirectionUrl: string, currentUrl: string) {
        const consistentUrl = new URL(redirectionUrl);
        const currentUrlObj = new URL(currentUrl.replace('#/', ''));
        consistentUrl.pathname = currentUrlObj.pathname;
        consistentUrl.search = currentUrlObj.search;
        this.navigationService.gotoUrl(consistentUrl.href);
    }

    private urlsHaveSameDomain(redirectionUrl: string, currentUrl: string) {
        return new URL(redirectionUrl).hostname === new URL(currentUrl).hostname;
    }

    private decodeReturnUrl(encodedReturnToUrl: string) {
        return atob(encodedReturnToUrl).replace(/(#|#\/)$/, '');
    }
}
